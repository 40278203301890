import i18n from "i18next";
import {initReactI18next} from "react-i18next";

import uz from "./languages/uz.json"
import en from "./languages/en.json"
import ru from "./languages/ru.json"

i18n
  .use(initReactI18next)
  .init({
    resources: {
      uz: uz,
      en: en,
      ru: ru
    },
    lng: 'uz',
    fallbackLng: 'uz',
    debug: false,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  }, function callback(e) {
    // console.log(e)
  })

export default i18n;