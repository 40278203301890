import React, {useContext} from "react";
import {Fade} from "react-awesome-reveal";
import {useTranslation} from "react-i18next";
import emoji from "react-easy-emoji";

import DisplayLottie from "../../../components/displayLottie/DisplayLottie";
import StyleContext from "../../../contexts/StyleContext";
import SocialMedia from "../../../components/socialMedia/SocialMedia";
import {contactInfo, illustration} from "../../../utils/portfolio";
import email from "../../../assets/lottie/email.json";
import "./Contact.scss";

const contactImg = require("../../../assets/images/contactMailDark.svg")


export default function Contact() {
  const {isDark} = useContext(StyleContext);
  const {t} = useTranslation();

  return (
    <Fade bottom duration={1000} distance="20px">
      <div className="main contact-margin-top" id="contact">
        <div className="contact-div-main">
          <div className="contact-header">
            <h1 className="heading contact-title">{emoji(t('contact-title'))}</h1>
            <p
              className={
                isDark
                  ? "dark-mode contact-subtitle"
                  : "subTitle contact-subtitle"
              }
            >
              {t('contact-subtitle')}
            </p>
            <div
              className={
                isDark ? "dark-mode contact-text-div" : "contact-text-div"
              }
            >
              {contactInfo.number && (
                <>
                  <a
                    className="contact-detail"
                    href={"tel:" + contactInfo.number}
                  >
                    {contactInfo.number}
                  </a>
                  <br/>
                  <br/>
                </>
              )}
              <a
                className="contact-detail-email"
                href={"mailto:" + contactInfo.email}
              >
                {contactInfo.email}
              </a>
              <br/>
              <br/>
              <SocialMedia/>
            </div>
          </div>
          <div className="contact-image-div">
            {illustration.animated ? (
              <DisplayLottie animationData={email}/>
            ) : (
              <img
                alt="Man working"
                src={contactImg}
              />
            )}
          </div>
        </div>
      </div>
    </Fade>
  );
}
