import React, {useContext} from "react";
import * as Effects from "react-awesome-reveal";

import "./Footer.scss";
import StyleContext from "../../contexts/StyleContext";
import {useTranslation} from "react-i18next";

const Fade = (props) => {
  return (
    <Effects.Fade>
      {props.children}
    </Effects.Fade>
  )
}

export default function Footer() {
  const {isDark} = useContext(StyleContext);
  const hostname = window.location.hostname;
  const {t} = useTranslation()

  return (
    <Fade bottom duration={1000} distance="5px">
      <div className="footer-div">
        <p className={isDark ? "dark-mode footer-text" : "footer-text"}>
          <span>{hostname}, {t('copyright')} &copy; 2017-{new Date().getFullYear()}</span>
        </p>
        <p className={isDark ? "dark-mode footer-text" : "footer-text"}>
          {" "}
        </p>
      </div>
    </Fade>
  );
}
