import React from "react";

import "./SoftwareSkill.scss";
import {skillsSection} from "../../../utils/portfolio";

export default function SoftwareSkill() {
  return (
    <div>
      <div className="software-skills-main-div">
        <ul className="dev-icons">
          {skillsSection.softwareSkills.map((skill, i) => {
            return (
              <li key={i} className="software-skill-inline">
                <i className={skill.awesomeClass}/>
                <p>{skill.name}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
