import React, {useContext} from "react";
import {NavLink} from "react-router-dom";
import Headroom from "react-headroom";
import {useTranslation} from "react-i18next";

import "./Header.scss";
import ToggleSwitch from "./components/ToggleSwitch";
import StyleContext from "../../contexts/StyleContext";
import {
  achievementSection,
  blogSection,
  greeting,
  skillsSection,
  talkSection,
  workExperiences
} from "../../utils/portfolio";

function Header() {
  const {isDark} = useContext(StyleContext);
  const viewExperience = workExperiences.display;
  const viewSkills = skillsSection.display;
  const viewAchievement = achievementSection.display;
  const viewBlog = blogSection.display;
  const viewTalks = talkSection.display;

  const {t, i18n} = useTranslation()

  function changeLanguage(e) {
    let lang = e.target.innerHTML
    i18n.changeLanguage(lang, null).then()
  }

  return (
    <Headroom>
      <header className={isDark ? "dark-menu header" : "header"}>
        <NavLink to="/" className="logo">
          <span className="grey-color">&lt;</span>
          <span className="logo-name">{greeting.username}</span>
          <span className="grey-color">/&gt;</span>
        </NavLink>
        <input className="menu-btn" type="checkbox" id="menu-btn"/>
        <label
          className="menu-icon"
          htmlFor="menu-btn"
          style={{color: "white"}}
        >
          <span className={isDark ? "navicon navicon-dark" : "navicon"}/>
        </label>
        <ul className={isDark ? "dark-menu menu" : "menu"}>
          {viewSkills && (
            <li>
              <a href="#skills">{t('skills')}</a>
            </li>
          )}
          {viewExperience && (
            <li>
              <a href="#experience">{t('work-experiences')}</a>
            </li>
          )}
          {viewAchievement && (
            <li>
              <a href="#achievements">{t('achievements')}</a>
            </li>
          )}
          {viewBlog && (
            <li>
              <a href="#blogs">{t('blogs')}</a>
            </li>
          )}
          {viewTalks && (
            <li>
              <a href="#talks">{t('talks')}</a>
            </li>
          )}
          <li>
            <a href="#contact">{t('contact-me')}</a>
          </li>
          <li>
            <a href="#toogle">
              <ToggleSwitch/>
            </a>
          </li>
        </ul>
      </header>
    </Headroom>
  );
}

export default Header;
