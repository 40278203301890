import React, {useContext} from "react";
import {Fade} from "react-awesome-reveal";
import emoji from "react-easy-emoji";

import "./Greeting.scss";
import landingPerson from "../../assets/lottie/landingPerson.json";
import DisplayLottie from "../../components/displayLottie/DisplayLottie";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";

import {greeting, illustration} from "../../utils/portfolio";
import StyleContext from "../../contexts/StyleContext";
import {Trans, useTranslation} from "react-i18next";

export default function Greeting() {
  const {isDark} = useContext(StyleContext);
  const {t} = useTranslation()


  if (!greeting.displayGreeting) {
    return null;
  }

  return (
    <Fade bottom duration={1000} distance="40px">
      <div className="greet-main" id="greeting">
        <div className="greeting-main">
          <div className="greeting-text-div">
            <div>
              <h1 className={isDark ? "dark-mode greeting-text" : "greeting-text"}>
                {t('greeting-title')}
                <span className="wave-emoji">{emoji("👋")}</span>
              </h1>
              <p className={
                isDark
                  ? "dark-mode greeting-text-p"
                  : "greeting-text-p subTitle"
              }
              >
                <Trans t={t}>
                  greeting-subtitle
                </Trans>
              </p>
              <SocialMedia/>
              <div className="button-greeting-div">
                <Button text={t('btn-contact')} href="#contact"/>
                <Button
                  text={t('btn-resume')}
                  newTab={true}
                  href={greeting.resumeLink}
                />
              </div>
            </div>
          </div>
          <div className="greeting-image-div">
            {illustration.animated ? (
              <DisplayLottie animationData={landingPerson}/>
            ) : (
              <img
                alt="man sitting on table"
                src={require("../../assets/images/manOnTable.svg")}
              />
            )}
          </div>
        </div>
      </div>
    </Fade>
  );
}
