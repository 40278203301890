import React, {useContext} from "react";
import {Fade} from "react-awesome-reveal";

import "./Skills.scss";
import StyleContext from "../../contexts/StyleContext";
import {illustration, skillsSection} from "../../utils/portfolio";
import codingPerson from "../../assets/lottie/codingPerson.json";
import DisplayLottie from "../../components/displayLottie/DisplayLottie";
import SoftwareSkill from "./software/SoftwareSkill";
import {useTranslation} from "react-i18next";
import emoji from "react-easy-emoji";
const skillImg = require("../../assets/images/developerActivity.svg");

export default function Skills() {
  const {isDark} = useContext(StyleContext);
  const {t} = useTranslation()

  if (!skillsSection.display) {
    return null;
  }

  const skillList = t('skill-list', {returnObjects: true})

  return (
    <div className={isDark ? "dark-mode main" : "main"} id="skills">
      <div className="skills-main-div">
        <Fade left duration={1000}>
          <div className="skills-image-div">
            {illustration.animated ? (
              <DisplayLottie animationData={codingPerson}/>
            ) : (
              <img
                alt="Man Working"
                src={skillImg}
              />
            )}
          </div>
        </Fade>
        <Fade right duration={1000}>
          <div className="skills-text-div">
            <h1
              className={isDark ? "dark-mode skills-heading" : "skills-heading"}
            >
              {t('skill-title')}
            </h1>
            <p
              className={
                isDark
                  ? "dark-mode subTitle skills-text-subtitle"
                  : "subTitle skills-text-subtitle"
              }
            >
              {t('skill-subtitle')}
            </p>
            <SoftwareSkill/>
            <div>
              {skillList.map((skill, i) => {
                return (
                  <p
                    key={i}
                    className={
                      isDark
                        ? "dark-mode subTitle skills-text"
                        : "subTitle skills-text"
                    }
                  >
                    {emoji(skill)}
                  </p>
                );
              })}
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
}
