import React, {createRef, useContext} from "react";
import {Fade, Slide} from "react-awesome-reveal";

import "./EducationCard.scss";
import StyleContext from "../../../contexts/StyleContext";

function GetDescBullets({descBullets}) {
  return descBullets
    ? descBullets.map((item, i) => (
      <li key={i} className="subTitle">
        {item}
      </li>
    ))
    : null;
}

export default function EducationCard({school}) {
  const imgRef = createRef();
  const {isDark} = useContext(StyleContext);

  const logo = require("../../../" + school.logo)

  return (
    <div>
      <Fade left duration={1000}>
        <div className="education-card">
          {school.logo && (
            <div className="education-card-left">
              <img
                crossOrigin={"anonymous"}
                ref={imgRef}
                className="education-roundedimg"
                src={logo}
                alt={school.name}
              />
            </div>
          )}
          <div className="education-card-right">
            <h5 className="education-text-school">{school.name}</h5>

            <div className="education-text-details">
              <h5
                className={
                  isDark
                    ? "dark-mode education-text-subHeader"
                    : "education-text-subHeader"
                }
              >
                {school.header}
              </h5>
              <p
                className={`${
                  isDark ? "dark-mode" : ""
                } education-text-duration`}
              >
                {school.duration}
              </p>
              <p className="education-text-desc">{school.desc}</p>
              <div className="education-text-bullets">
                <ul>
                  <GetDescBullets descBullets={school.descBullets}/>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Fade>
      <Slide left duration={2000}>
        <div className="education-card-border"/>
      </Slide>
    </div>
  );
}
