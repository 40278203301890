import React, {useContext} from "react";
import {Fade} from "react-awesome-reveal";

import "./WorkExperience.scss";
import ExperienceCard from "./experienceCard/ExperienceCard";
import {workExperiences} from "../../utils/portfolio";
import StyleContext from "../../contexts/StyleContext";
import {useTranslation} from "react-i18next";


export default function WorkExperience() {
  const {isDark} = useContext(StyleContext);
  const {t} = useTranslation()

  if (workExperiences.display) {
    return (
      <div id="experience">
        <Fade bottom duration={1000} distance="20px">
          <div className="experience-container" id="workExperience">
            <div>
              <h1 className="experience-heading">{t('experiences')}</h1>
              <div className="experience-cards-div">
                {workExperiences.experience.map((card, i) => {
                  return (
                    <ExperienceCard
                      key={i}
                      isDark={isDark}
                      cardInfo={{
                        company: card.company,
                        desc: card.desc,
                        date: card.date,
                        logo: card.logo,
                        role: card.role,
                        descBullets: card.descBullets
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </Fade>
      </div>
    );
  }
  return null;
}
