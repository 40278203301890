import React, {useEffect, useState} from "react";

import Header from "../components/header/Header";
import Greeting from "./greeting/Greeting";
import Skills from "./skills/Skills";
import StackProgress from "./proficiencies/skillProgress";
import WorkExperience from "./workExperience/WorkExperience";
import Projects from "./projects/Projects";
import StartupProject from "./startups/StartupProject";
import Achievement from "./achievement/Achievement";
import Blogs from "./blogs/Blogs";
import Footer from "../components/footer/Footer";
import Education from "./education/Education";
import ScrollToTopButton from "./topbutton/Top";
import Profile from "./profile/Profile";
import SplashScreen from "./splash/SplashScreen";

import {splashScreen} from "../utils/portfolio";
import {StyleProvider} from "../contexts/StyleContext";
import {useLocalStorage} from "../hooks/useLocalStorage";
import "./Main.scss";

const Main = () => {
    const darkPref = window.matchMedia("(prefers-color-scheme: dark)");
    const [isDark, setTheme] = useLocalStorage("isDark", darkPref.matches);
    const [isShowingSplashAnimation, setIsShowingSplashAnimation] = useState(true);

    useEffect(() => {
        if (splashScreen.enabled) {
            const splashTimer = setTimeout(
                () => setIsShowingSplashAnimation(false),
                splashScreen.duration
            );
            return () => {
                clearTimeout(splashTimer);
            };
        }
    }, []);

    const changeTheme = () => {
        setTheme(!isDark);
    };

    return (
        <div className={isDark ? "dark-mode" : null}>
            <StyleProvider value={{isDark: isDark, changeTheme: changeTheme}}>
                {isShowingSplashAnimation && splashScreen.enabled ? (
                    <SplashScreen/>
                ) : (
                    <>
                        <Header/>
                        <Greeting/>
                        <Skills/>
                        <StackProgress/>
                        <Education/>
                        <WorkExperience/>
                        <Projects/>
                        <StartupProject/>
                        <Achievement/>
                        <Blogs/>
                        {/*<Talks/>*/}
                        {/*<Twitter/>*/}
                        {/*<Podcast/>*/}
                        <Profile/>
                        <Footer/>
                        <ScrollToTopButton/>
                    </>
                )}
            </StyleProvider>
        </div>
    );
};

export default Main;
