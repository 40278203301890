/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "../assets/lottie/splashAnimation.json"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "Anvar-Avenger",
  name: "Anvar",
  resumeLink: "https://docs.google.com/document/d/167rsqeoo45aYx0INuy2MuCzqWOYjzrkdSnCAS3G0W5A/export?format=pdf",
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/Anvar-Avenger",
  linkedin: "https://www.linkedin.com/in/anvar-zaripboyev-b51960276",
  gmail: "anvarindastries@gmail.com",
  gitlab: "https://gitlab.com/tezkor",
  facebook: "https://www.facebook.com/anvar.zaripboyev.39",
  medium: "https://medium.com/@anvarindastries",
  stackoverflow: "https://stackoverflow.com/users/13472843/anvar-zaripboyev",
  instagram: "https://www.instagram.com/tezkordasturchi",
  telegram: "https://t.me/anvar_dasturchi",
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  skills: [
    emoji(
      "⚡ Develop highly interactive Front end / User Interfaces for your web and mobile applications"
    ),
    emoji("⚡ Progressive Web Applications ( PWA ) in normal and SPA Stacks"),
    emoji(
      "⚡ Integration of third party services such as Firebase/ AWS / Digital Ocean"
    )
  ],

  softwareSkills: [
    {
      name: "C++",
      awesomeClass: "fas fa-copyright"
    },
    {
      name: "Java",
      awesomeClass: "fab fa-java"
    },
    {
      name: "Python",
      awesomeClass: "fab fa-python"
    },
    {
      name: "JavaScript",
      awesomeClass: "fab fa-js"
    },
    {
      name: "PHP",
      awesomeClass: "fab fa-php"
    },
    {
      name: "TypeScript",
      awesomeClass: "fab fa-typescript"
    },
    {
      name: "C#",
      awesomeClass: "fas fa-copyright"
    },
    {
      name: "Kotlin",
      awesomeClass: "fab fa-kotlin"
    },
    {
      name: "Go",
      awesomeClass: "fab fa-golang"
    },
    {
      name: "HTML5",
      awesomeClass: "fab fa-html5"
    },
    {
      name: "CSS3",
      awesomeClass: "fab fa-css3-alt"
    },
    {
      name: "nodejs",
      awesomeClass: "fab fa-node"
    },
    {
      name: "ReactJS",
      awesomeClass: "fab fa-react"
    },
    {
      name: "VueJS",
      awesomeClass: "fab fa-vuejs"
    },
    {
      name: "Angular",
      awesomeClass: "fab fa-angular"
    },
    {
      name: "SASS (SCSS)",
      awesomeClass: "fab fa-sass"
    },
    {
      name: "SQL",
      awesomeClass: "fas fa-database"
    },
    {
      name: "MYSQL8",
      awesomeClass: "fas fa-server"
    },
    {
      name: "PostgresSQL",
      awesomeClass: "fas fa-server"
    },
    {
      name: "MongoDB",
      awesomeClass: "fas fa-server"
    },
    {
      name: "SQLite3",
      awesomeClass: "fas fa-database"
    },
    {
      name: "ExpressJS",
      awesomeClass: "fab fa-node-js"
    },
    {
      name: "Laravel",
      awesomeClass: "fab fa-laravel"
    },
    {
      name: "Spring",
      awesomeClass: "fas fa-seedling"
    },
    {
      name: "Flask",
      awesomeClass: "fab fa-python"
    },
    {
      name: "Django",
      awesomeClass: "fab fa-python"
    },
    {
      name: "NestJS",
      awesomeClass: "fab fa-node-js"
    },
    {
      name: "Android",
      awesomeClass: "fab fa-android"
    },
    {
      name: "Dart",
      awesomeClass: "fab fa-dart"
    },
    {
      name: "npm",
      awesomeClass: "fab fa-npm"
    },
    {
      name: "pip",
      awesomeClass: "fab fa-python"
    },
    {
      name: "firebase",
      awesomeClass: "fas fa-fire"
    },
    {
      name: "docker",
      awesomeClass: "fab fa-docker"
    },
    {
      name: "Arduino",
      awesomeClass: "fas fa-infinity"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      name: "Gurlan kasb-hunar kolleji, Gurlan, Xorazm",
      logo: "assets/images/logo-college.webp",
      header: "Komyuter va Dasturiy ta\u2019minot muhandisi",
      duration: "2017 - 2019",
      desc: "Kompyuter tizimlariga texnik xizmat ko\u2018rsatuvchi",
      descBullets: [
        "Dasturiy ta\u2018minot o\u2018rnatuvchi va kompyuter texnigi, ofis jihozlariga dasturiy xizmat ko\u2018rsatish",
        "A\u2019lo darajali diplom"
      ]
    },
    {
      name: "Toshkent Axborot Texnologiyalari universiteti Urganch filiali, Urganch, Xorazm",
      logo: "assets/images/ubtuit-logo.png",
      header: "Dasturiy muhandis",
      duration: "2019 - 2023",
      desc: "Dasturiy muhandis, Veb xizmatlar, IOT tizimlari, ...",
      descBullets: [
        "Algoritmlash asoslari yo\u2018nalishi bo\u2018yicha olimpiadachi",
        "Iqtidorli talabalar jamoasi a\u2019zosi"
      ]
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      stack: "frontend", //Insert stack or technology you have experience in
      percentage: "80%" //Insert relative proficiency in percentage
    },
    {
      stack: "backend",
      percentage: "90%"
    },
    {
      stack: "mobile",
      percentage: "60%"
    },
    {
      stack: "english",
      percentage: "60%"
    },
    {
      stack: "technic",
      percentage: "50%"
    }
  ]
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Full-Stack dasturchi",
      company: "TATU UF shaxsiy jamoa",
      logo: "assets/images/atom.svg",
      date: "2021 – 2023",
      desc: "Barcha loyihalarda Backend dasturchi sifatida va yetakchi FrontEnd dasturchi sifatida",
      descBullets: [
        "HududGaz Xorazm",
        "OCS - Avtomobillar moy almashtirish tizimi",
        "LiderTaxi 1420 - Taksi dasturchi"
      ]
    },
    {
      role: "O\u2018qituvchi",
      company: "Amusoft",
      logo: "assets/images/amusoft-logo.svg",
      date: "2022",
      desc: "Dasturlash bo\u2018yicha talablarni o\u2018qitish. Haqiqiy loyihalarda ishtirok etishni tashkillashtirish"
    },
    {
      role: "Dasturchi",
      company: "CitronLab",
      logo: "assets/images/lemon.jpeg",
      date: "2023",
      desc: "Ardunio orqali tizimlarni boshqarish. Qurilmaga dastur yozish!"
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: true // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  projects: [
    {
      image: "https://hududgaz.uz/assets/images/app-logo.png",
      name: "HududGaz Xorazm",
      description: "Arizlar bilan ishlaga mo\u2018ljallangan yopiq tizim",
      links: [
        {
          name: "O\u2018tish",
          url: "#"
        }
      ]
    },
    {
      image: "https://play-lh.googleusercontent.com/rExz31Rpqi83eVDv6p9oRdzOTOUq8c-TfWJi6Ou1iuJd1oGH21WIdvn57co8N6nISks=w240-h480-rw",
      name: "LiderTaxi",
      description: "Mijozlar uchun qulay tizim",
      links: [
        {
          name: "O\u2018tish",
          url: "https://play.google.com/store/apps/details?id=com.idealcoders.taxiapp"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  achievementsCards: [
    {
      title: "Bizda Bor",
      subtitle: "Bizda bor mobil ilovasi, kvartira izlayotgan uchun ajoyib tizim. Turar-joy izlovchilar ham ijaraga beruvchilar ham bu ilovadan foydalanishi mumkin!",
      image: "https://my.ima.uz/images/logo-new.png",
      imageAlt: "Image",
      footerLink: [
        {
          name: "Guvohnoma",
          url: "https://drive.google.com/file/d/1P4j9H7ph2s5lYEwwYBCdiCv09iU6Gi0S/view?usp=drive_link"
        },
        {
          name: "Dastur",
          url: "https://ijara.joriy.uz/app.apk"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  displayMediumBlogs: "true", // Set true to display fetched medium blogs instead of hardcoded ones
  blogs: [
    {
      url: "http://amusoft.uz/singlepage/6",
      title: "Yangi o\u2018quv-kurslarimiz tez orada boshlanadi",
      description: "Tanlovda qatnashing va bepul, kurs yutib oling!"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [
    {
      title: "Build Actions For Google Assistant",
      subtitle: "Codelab at GDG DevFest Karachi 2019",
      slides_url: "https://bit.ly/-slides",
      event_url: "https://www.facebook.com/events/2339906106275053/"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcasts: [],
  display: true // Set false to hide this section, defaults to true
};

const contactInfo = {
  number: "+998 (99) 512-32-64",
  email: "anvarindastries@gmail.com"
};

// Twitter Section

const twitterDetails = {
  userName: "twitter", //Replace "twitter" with your twitter username without @
  display: true // Set true to display this section, defaults to false
};

const isHireable = false; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  isHireable
};
